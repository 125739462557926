<template>
    <div class="align-self-center ms-auto me-2">
        <template v-if="o_dataObject?.layoutManager && !o_dataObject.layoutManager.layoutsValidated">
            <div class="spinner-border spinner-border-sm text-primary" role="status"
                :title="$t('Validating layouts')">
                <span class="visually-hidden">Loading...</span>
            </div>
        </template>
        <template v-else-if="!o_dataObject.layoutManager.activeLayoutValid">
            <i class="bi bi-exclamation-triangle-fill text-warning mx-1" :title="$t(`Currently applied layout is outdated, reapply layout to continue tracking changes.`)"></i>
            <b class="text-truncate">{{$t(`Current layout is not applied`)}}</b>
            <button type="button" class="btn btn-outline-primary btn-sm mx-1" @click="reapplyLayout">{{$t('Reapply')}}</button>
        </template>
        <template v-if="o_dataObject?.layoutManager.isSaving">
            <div class="spinner-border spinner-border-sm text-primary" role="status"
                :title="$t('Saving layout')">
                <span class="visually-hidden">Saving...</span>
            </div>
        </template>
    </div>
</template>

<script setup>
import { InjectionKeys } from 'o365-utils';
import { inject, computed } from 'vue';

const props = defineProps({
   dataObject: Object, 
});

const dataGridControl = inject(InjectionKeys.dataGridControlKey, null);

const o_dataObject = computed(() => {
    return props.dataObject ?? dataGridControl?.value?.dataObject; 
});

function reapplyLayout() {
    o_dataObject.value?.layoutManager?.reapplyLayout();
}

</script>